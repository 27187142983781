import { Injectable } from '@angular/core';
import {
  ApplianceFormValueWithItemId,
  Basket,
  Quote,
  RedeyeTags,
} from '@common/util-models';
import {
  distinctUntilChangedDeep,
  filterNullUndefined,
} from '@domgen/dgx-fe-common';
import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class QuoteSummaryRedeyeMapperService {
  getDeleteQuoteData(): OperatorFunction<
    [Quote, ApplianceFormValueWithItemId, Basket],
    RedeyeTags[]
  > {
    return pipe(
      distinctUntilChangedDeep(),
      filterNullUndefined(),
      map(([quote, formValues, basket]) => [
        {
          nourl: 'basket-deletion',
          delete_event: 'confirm',
          delete_item_type: quote.applianceName,
          delete_item_brand: quote.brandName,
          delete_item_guar: formValues.underGuarantee,
          delete_item_purchmonth: quote.purchaseDate.toLocaleDateString(
            'default',
            { month: 'long' }
          ),
          delete_item_purchyear: quote.purchaseDate.getFullYear().toString(),
          delete_item_purchval: formValues.purchasePrice,
          delete_item_coverval:
            quote.cover.paymentOptions.directDebit?.subsequentPayment
              .toFixed(2)
              .toString(),
          request_action: basket.requestAction,
          request_source: basket.requestSource,
        },
      ])
    );
  }
}
