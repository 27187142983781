import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import {
  QuotesFacade,
  SelectQuotePaymentOptionsFacade,
} from '@common/data-access-quotes';
import { ResetStateFacade } from '@common/data-access-shared';
import { UserProfileFacade } from '@common/data-access-user-profile';
import { fadeInOut } from '@common/ui-sales-components';
import {
  BuildConfigService,
  LoaderService,
  MetaDataService,
  SessionInactivityService,
} from '@common/util-foundation';
import { BuildConfig, Quote } from '@common/util-models';
import { AuthLibFacade } from '@domgen/dgx-fe-auth';
import { NavigationLink } from '@domgen/dgx-fe-business-models';
import { AlertDialogService } from '@domgen/dgx-fe-components-core';
import { QuantumMetricsService } from '@domgen/dgx-fe-qm';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthStateService } from '@shared/feature-auth';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, mapTo, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'sales-app-root',
  templateUrl: './sales-app.component.html',
  styleUrls: ['./sales-app.component.scss'],
  animations: [fadeInOut],
})
export class SalesAppComponent implements OnInit {
  title = 'sales';
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLink[] = [
    this.config.header?.products,
    this.config.header?.repair,
    this.config.header?.faq,
  ];
  loading$!: Observable<boolean>;
  isCheckoutPage = false;

  public progressSteps$:
    | Observable<{ label: string; routes: string[] }[]>
    | undefined;
  public currentRoute = '';

  constructor(
    public readonly buildConfigService: BuildConfigService,
    public readonly userProfileFacade: UserProfileFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertDialogService: AlertDialogService,
    private readonly authFacade: AuthLibFacade,
    private readonly authStateService: AuthStateService,
    private readonly loaderService: LoaderService,
    private readonly metaDataService: MetaDataService,
    private readonly quantumMetricsService: QuantumMetricsService,
    private readonly resetStateFacade: ResetStateFacade,
    private readonly router: Router,
    private readonly selectQuotePaymentOptionsFacade: SelectQuotePaymentOptionsFacade,
    private readonly sessionInactivityService: SessionInactivityService,
    private quotesFacade: QuotesFacade
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url) {
          this.currentRoute = e.url;
          this.isCheckoutPage =
            this.buildConfigService.config.checkoutLoggedInUserPage ===
            this.currentRoute;
        }
      });

    this.progressSteps$ = combineLatest([
      this.selectQuotePaymentOptionsFacade.paymentOptions$,
      this.authFacade.isAuthenticated$,
    ]).pipe(
      map(([paymentOptions, isAuthenticated]) => {
        // In case there are more then one payment method then it should be boiler (heating equipment)
        return this.getSteps(
          isAuthenticated,
          paymentOptions.card !== undefined &&
            paymentOptions.directDebit !== undefined
        );
      })
    );
    this.quantumMetricsService.trackNavigation();
  }

  ngOnInit(): void {
    this.loading$ = this.loaderService.loading$;
    this.authStateService.setUpdateAuthState();
    this.monitorSessionInactivity();
    this.setMetaData();
  }

  private getSteps(
    isAuthenticated: boolean,
    isHeating: boolean
  ): { label: string; routes: string[] }[] {
    const yourDetails = {
      label: 'Your Details',
      routes: ['/checkout/personal-details'],
    };
    const paymentMethod = {
      label: 'Payment Method',
      routes: ['/checkout/select-payment'],
    };
    const paymentDetails = {
      label: 'Payment Details',
      routes: [
        '/checkout/direct-debit-payment',
        '/checkout/card-payment',
        '/checkout/credit-card-payment',
        '/checkout/logged-in',
      ],
    };
    const confirmation = {
      label: 'Confirmation',
      routes: ['/checkout/order-confirmation'],
    };
    return [
      ...(!isAuthenticated ? [yourDetails] : []),
      ...(isHeating ? [paymentMethod] : []),
      paymentDetails,
      confirmation,
    ];
  }

  private monitorSessionInactivity(): void {
    this.sessionInactivityService
      .monitor()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.alertDialogService.openError({
          ariaLabel: 'session expired',
          disableClose: true,
          data: {
            title: 'Your session has timed out due to inactivity',
            body: 'Click below to restart the journey',
            primaryCta: {
              text: 'Restart journey',
              click: () => this.handleTimeout(),
            },
          },
        });
      });
  }

  private handleItemRedirect(items: Quote[]) {
    if (items.length > 1) {
      // go back to start because we have more than 1 item, doesnt matter if P&D or not
      this.resetStateFacade.sessionInactivityTimeout();
    } else {
      // if more than 1 items we need to tell if this 1 item is P&D or not
      this.quotesFacade.hasPlumbingAndDrainageQuote$
        .pipe(
          first(),
          map((hasPD) => {
            if (hasPD) {
              this.resetStateFacade.sessionInactivityTimeoutPD();
            } else {
              this.resetStateFacade.sessionInactivityTimeout();
            }
          })
        )
        .subscribe();
    }
  }

  private handleTimeout() {
    this.quotesFacade.multiItemQuotes$
      .pipe(
        first(),
        map((items) => {
          if (items.length > 0) {
            this.handleItemRedirect(items);
          } else {
            // if no items in basket then we have to look at the url
            this.router.url.indexOf(
              this.buildConfigService.config.plumbingAndDrainagePage
            ) > -1
              ? this.resetStateFacade.sessionInactivityTimeoutPD()
              : this.resetStateFacade.sessionInactivityTimeout();
          }
        })
      )
      .subscribe();
  }

  private setMetaData(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        mapTo(this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((data: Data) => {
        this.metaDataService.setTitle(data['title']);
        this.metaDataService.setDescription(
          data['title'] + data['description']
        );
      });
  }
}
