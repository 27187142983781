import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckoutFacade } from '@common/data-access-checkout';
import { CheckoutBasketFacade } from '@common/data-access-checkout-basket';
import { OrderConfirmationFacade } from '@common/data-access-checkout-order-confirmation';
import { PersonalDetailsFacade } from '@common/data-access-personal-details';
import { SelectPaymentFacade } from '@common/data-access-select-payment';
import { UserProfileFacade } from '@common/data-access-user-profile';
import {
  CheckoutRequest,
  CoverPaymentOptions,
  CustomerAddress,
  PaymentType,
  YourDetails,
} from '@common/util-models';
import { combineLatestObj, filterNullUndefined } from '@domgen/dgx-fe-common';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { PaymentFlexConfig } from '../../models/payment-flex/payment-flex-config.interface';
import { PaymentFlexConfirmation } from '../../models/payment-flex/payment-flex-confirmation.interface';
import { PaymentFlexCustomerDetails } from '../../models/payment-flex/payment-flex-customer-details.interface';
import { PaymentFlexIframeUrls } from '../../models/payment-flex/payment-flex-iframe-url.interface';
import { PaymentFlexRequest } from '../../models/payment-flex/payment-flex-request.interface';
import { PaymentFlexStatus } from '../../models/payment-flex/payment-flex-status.enum';
import { PAYMENT_FLEX_CONFIG } from '../../tokens/payment-flex-config.token';
import {
  formatCustomerAddress,
  formatCustomerDetailsToPaymentApi,
  formatPaymentConfirmation,
  formatPaymentFlexToCheckoutRequest,
  formatPaymentRequest,
  formatPaymentRequestPayload,
} from '../../utils/checkout-payment-options.utils';
import { PaymentFlexApiService } from '../payment-flex-api/payment-flex-api.service';

export interface ViewModel {
  iframeVisible: boolean;
  paymentFlexUrls?: PaymentFlexIframeUrls;
  paymentMethod?: PaymentType;
  paymentOptions: CoverPaymentOptions;
  yourDetails: YourDetails;
}

export interface ComponentState {
  iframeVisible: boolean;
  paymentFlexUrls?: PaymentFlexIframeUrls;
  paymentMethod?: PaymentType;
}

@Injectable()
export class CheckoutPaymentOptionsComponentService extends ComponentStore<ComponentState> {
  private readonly customerDetails$: Observable<PaymentFlexCustomerDetails> =
    this.personalDetailsFacade.personalDetails$.pipe(
      withLatestFrom(
        this.retrieveCustomerAddress(this.paymentConfig.countryIso2Code)
      ),
      map(([personalDetails, address]) =>
        formatCustomerDetailsToPaymentApi(personalDetails, address)
      )
    );

  private readonly paymentFlexEvents$: Observable<
    PaymentFlexConfirmation | undefined
  > = fromEvent(window, 'message').pipe(
    filter((paymentConfirmationEvent) =>
      (
        paymentConfirmationEvent as unknown as MessageEvent<string>
      ).origin.includes('paysupervisor')
    ),
    map((paymentConfirmationEvent) =>
      formatPaymentConfirmation(
        paymentConfirmationEvent as unknown as MessageEvent<string>
      )
    )
  );

  private readonly paymentFlexPayload$: Observable<PaymentFlexRequest[]> =
    this.checkoutBasketFacade.getBasket$.pipe(
      withLatestFrom(this.customerDetails$),
      map(([basket, customerDetails]) => [
        formatPaymentRequestPayload(basket, PaymentType.Card, customerDetails),
        formatPaymentRequestPayload(
          basket,
          PaymentType.DirectDebit,
          customerDetails
        ),
      ])
    );

  private readonly paymentRequests$: Observable<PaymentFlexIframeUrls> =
    this.paymentFlexPayload$.pipe(
      withLatestFrom(this.checkoutBasketFacade.paymentOptions$),
      formatPaymentRequest(this.paymentFlexApiService, this.domSanitizer)
    );

  //Selectors
  private readonly paymentFlexUrls$: Observable<
    PaymentFlexIframeUrls | undefined
  > = this.select((state: ComponentState) => state.paymentFlexUrls);

  private readonly paymentMethod$: Observable<PaymentType | undefined> =
    this.select((state: ComponentState) => state.paymentMethod);

  private readonly iframeVisible$: Observable<boolean> = this.select(
    (state: ComponentState) => state.iframeVisible
  );

  vm$: Observable<ViewModel> = combineLatestObj({
    paymentFlexUrls: this.paymentFlexUrls$,
    paymentMethod: this.paymentMethod$,
    paymentOptions: this.checkoutBasketFacade.paymentOptions$,
    iframeVisible: this.iframeVisible$,
    yourDetails: this.orderConfirmationFacade.personalDetails$,
  });

  constructor(
    @Inject(PAYMENT_FLEX_CONFIG) private paymentConfig: PaymentFlexConfig,
    private readonly checkoutBasketFacade: CheckoutBasketFacade,
    private readonly checkoutFacade: CheckoutFacade,
    private readonly domSanitizer: DomSanitizer,
    private readonly orderConfirmationFacade: OrderConfirmationFacade,
    private readonly paymentFlexApiService: PaymentFlexApiService,
    private readonly personalDetailsFacade: PersonalDetailsFacade,
    private readonly selectPaymentFacade: SelectPaymentFacade,
    private readonly userProfileFacade: UserProfileFacade
  ) {
    super({
      iframeVisible: false,
    });
  }

  //Updaters
  private readonly updatePaymentFlexUrls = this.updater(
    (state: ComponentState, paymentFlexUrls: PaymentFlexIframeUrls) => ({
      ...state,
      paymentFlexUrls,
    })
  )(this.paymentRequests$);

  private readonly updateIframeVisible = this.updater(
    (state: ComponentState, iframeVisible: boolean) => ({
      ...state,
      iframeVisible,
    })
  );

  readonly updatePaymentMethod = this.updater(
    (state: ComponentState, paymentMethod?: PaymentType) => ({
      ...state,
      paymentMethod,
    })
  );

  //Effects
  readonly onPaymentMethodChange = this.effect(
    (paymentMethod$: Observable<PaymentType>) =>
      paymentMethod$.pipe(
        tap((paymentMethod) =>
          this.selectPaymentFacade.selectPaymentOption(paymentMethod)
        )
      )
  )(this.paymentMethod$.pipe(filterNullUndefined()));

  readonly onSubmit = this.effect((submission$) =>
    submission$.pipe(
      withLatestFrom(this.paymentMethod$, this.paymentFlexUrls$),
      filter(([, paymentMethod, paymentFlexUrl]) =>
        Boolean(paymentMethod && paymentFlexUrl)
      ),
      tap(() => this.updateIframeVisible(true))
    )
  );

  readonly onPaymentUrlUpdate: Subscription = this.effect(
    (paymentFlexUrls$: Observable<PaymentFlexIframeUrls | undefined>) =>
      paymentFlexUrls$.pipe(
        filterNullUndefined(),
        filter((paymentFlexUrls: PaymentFlexIframeUrls) =>
          Boolean(
            paymentFlexUrls[PaymentType.DirectDebit] &&
              !paymentFlexUrls[PaymentType.Card]
          )
        ),
        tap(() => this.updateIframeVisible(true))
      )
  )(this.paymentFlexUrls$);

  readonly onPaymentConfirmation: Subscription = this.effect(
    (
      paymentConfirmationEvent$: Observable<PaymentFlexConfirmation | undefined>
    ) =>
      paymentConfirmationEvent$.pipe(
        withLatestFrom(
          this.checkoutBasketFacade.getBasket$,
          this.retrieveCustomerAddress(this.paymentConfig.countryIso3Code),
          this.userProfileFacade.personDetails$,
          this.personalDetailsFacade.personalDetailsState$,
          this.paymentMethod$
        ),
        map(
          ([
            paymentConfirmation,
            checkoutBasket,
            customerAddress,
            loggedInPersonDetails,
            guestPersonalDetails,
            paymentMethod,
          ]) =>
            formatPaymentFlexToCheckoutRequest(
              paymentConfirmation,
              checkoutBasket,
              customerAddress,
              loggedInPersonDetails,
              guestPersonalDetails,
              paymentMethod
            )
        ),
        filter(Boolean),
        tap((checkoutRequest: CheckoutRequest) =>
          this.checkoutFacade.checkoutPaymentFlex(checkoutRequest)
        )
      )
  )(
    this.paymentFlexEvents$.pipe(
      filter(
        (paymentFlexConfirmation?: PaymentFlexConfirmation) =>
          paymentFlexConfirmation?.Status === PaymentFlexStatus.Success
      ),
      tap(() => this.updateIframeVisible(false))
    )
  );

  private retrieveCustomerAddress(
    countryCode: string
  ): Observable<CustomerAddress> {
    return combineLatest([
      this.userProfileFacade.personDetails$,
      this.personalDetailsFacade.personalDetailsState$,
    ]).pipe(
      map(([loggedInPersonDetails, guestPersonalDetails]) =>
        formatCustomerAddress(
          countryCode,
          loggedInPersonDetails,
          guestPersonalDetails
        )
      )
    );
  }
}
