<div class="form-box">
  <div class="form-box__wrapper">
    <h3>Would you like to stay in touch?</h3>
    <div class="my-6">
      <div>
        We'd love to send you updates with the latest news and offers from time
        to time, always in line with our
        <span
          ><a class="link" target="_blank" [href]="privacyPolicyUrl">
            Privacy Policy
          </a></span
        >. If you're happy for us to do this, select the ways you’d like to stay
        in touch.
      </div>
    </div>
    <ng-container *ngIf="vm$ | async as vm">
      <div class="form-panel">
        <dgx-dfb-form
          [fieldset]="vm.fieldDef"
          [form]="vm.formGroup"
          [validate]="vm.validate"
          (analytics)="handleAnalytics($event)"
        >
        </dgx-dfb-form>
      </div>
    </ng-container>
  </div>
</div>
