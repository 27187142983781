import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentFlexConfig } from '../../models/payment-flex/payment-flex-config.interface';
import { PaymentFlexRequest } from '../../models/payment-flex/payment-flex-request.interface';
import { PaymentFlexResponse } from '../../models/payment-flex/payment-flex-response.interface';
import { PAYMENT_FLEX_CONFIG } from '../../tokens/payment-flex-config.token';

@Injectable()
export class PaymentFlexApiService {
  readonly request = (
    paymentFlexPayload: PaymentFlexRequest
  ): Observable<PaymentFlexResponse> =>
    this.paymentConfig.paymentApiUrl
      ? this.httpClient
          .post<{ result: PaymentFlexResponse }>(
            this.paymentConfig.paymentApiUrl,
            paymentFlexPayload,
            {
              headers: {
                ...this.getHeaders(),
              },
            }
          )
          .pipe(
            map((response: { result: PaymentFlexResponse }) => response.result)
          )
      : EMPTY;

  constructor(
    @Optional()
    @Inject(PAYMENT_FLEX_CONFIG)
    private paymentConfig: PaymentFlexConfig,
    private readonly httpClient: HttpClient
  ) {}

  private getHeaders() {
    return {
      'wl-client': this.paymentConfig.config.wlClient,
      'request-source': this.paymentConfig.config.catalogueStore,
      'request-action': this.paymentConfig.config.action,
    };
  }
}
