<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <dgx-form-row>
    <dgx-form-field [dgxFormField]="formField" formControlName="paymentMethod">
      <dgx-form-radio
        class="checkout-payment-method__radio"
        [inline]="true"
        [options]="options"
      ></dgx-form-radio
    ></dgx-form-field>
  </dgx-form-row>
  <ui-button type="submit" *ngIf="buttonVisible">Continue</ui-button>
</form>
