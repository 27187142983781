import { QuoteSummaryConfig, WhichTitle } from '@common/util-models';

export const defaultQuoteSummaryConfig: QuoteSummaryConfig = {
  whichTitle: WhichTitle.brandAppliance,
  isForSmallScreen: false,
  hideTrustMarks: false,
};

export const defaultQuoteSummaryConfigSmall: QuoteSummaryConfig = {
  ...defaultQuoteSummaryConfig,
  isForSmallScreen: true,
};
