import { BuildConfigService } from '@common/util-foundation';
import { RuntimeConfig } from '@common/util-models';
import { CountryIso2Code, CountryIso3Code } from '@domgen/dgx-fe-i18n';
import { PaymentFlexConfig } from '../models/payment-flex/payment-flex-config.interface';

export const paymentFlexConfigFactory = (
  runtimeConfig: RuntimeConfig,
  buildConfigService: BuildConfigService,
  countryIso2Code: CountryIso2Code,
  countryIso3Code: CountryIso3Code
): PaymentFlexConfig => ({
  countryIso2Code,
  countryIso3Code,
  config: {
    action: buildConfigService.config.action,
    catalogueStore: buildConfigService.config.catalogueStore,
    wlClient: buildConfigService.config.wlClient,
    route: buildConfigService.config.paymentOptionsPage,
  },
  paymentApiUrl: runtimeConfig.paymentApiUrl
    ? `${runtimeConfig.paymentApiUrl}/payment-url`
    : undefined,
});
