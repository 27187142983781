import { Inject, Injectable } from '@angular/core';
import { ApplianceDetailsFacade } from '@common/data-access-appliance-details';
import { QuotesFacade } from '@common/data-access-quotes';
import {
  ENVIRONMENT,
  pushToRedeye,
  RedeyeTaggingService,
} from '@common/util-foundation';
import { Environment, Quote } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { QuoteSummaryRedeyeMapperService } from './quote-summary-redeye-mapper.service';

@Injectable()
export class QuoteSummaryRedeyeTaggingService extends ComponentStore<never> {
  readonly tagDeleteQuote = this.effect((trigger$: Observable<Quote>) =>
    trigger$.pipe(
      filter(() => Boolean(this.environment.redEyeTagging)),
      concatLatestFrom((quote: Quote) => [
        this.applianceDetailsFacade.selectFormById(quote.itemId),
        this.quotesFacade.basket$.pipe(filterNullUndefined()),
      ]),
      this.quoteSummaryRedeyeMapperService.getDeleteQuoteData(),
      pushToRedeye(this.redeyeTaggingService)
    )
  );

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private readonly quoteSummaryRedeyeMapperService: QuoteSummaryRedeyeMapperService,
    private readonly redeyeTaggingService: RedeyeTaggingService,
    private readonly applianceDetailsFacade: ApplianceDetailsFacade,
    private readonly quotesFacade: QuotesFacade
  ) {
    super();
  }
}
