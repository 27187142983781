/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DomSanitizer } from '@angular/platform-browser';
import {
  CheckoutBasket,
  CheckoutBasketItem,
  CheckoutRequest,
  CoverPaymentOptions,
  CustomerAddress,
  ItemType,
  PaymentByPaymentFlexToken,
  PaymentType,
  Person,
  PersonalDetails,
  PersonalDetailsState,
  PlanDocumentsSendMethod,
} from '@common/util-models';
import { forkJoin, map, mergeMap, of, OperatorFunction, pipe } from 'rxjs';
import { PaymentFlexConfirmation } from '../models/payment-flex/payment-flex-confirmation.interface';
import { PaymentFlexCustomerDetails } from '../models/payment-flex/payment-flex-customer-details.interface';
import { PaymentFlexIframeUrls } from '../models/payment-flex/payment-flex-iframe-url.interface';
import { PaymentFlexRequest } from '../models/payment-flex/payment-flex-request.interface';
import { PaymentFlexResponse } from '../models/payment-flex/payment-flex-response.interface';
import { PaymentFlexStatus } from '../models/payment-flex/payment-flex-status.enum';
import { PaymentFlexApiService } from '../services/payment-flex-api/payment-flex-api.service';

export const formatCustomerAddress = (
  countryCode: string,
  loggedInPersonDetails?: Person,
  guestPersonalDetails?: PersonalDetailsState
): CustomerAddress => {
  return {
    addressLine1: loggedInPersonDetails?.correspondenceAddress.addressLine1
      ? loggedInPersonDetails.correspondenceAddress.addressLine1
      : guestPersonalDetails?.address?.line1 ?? '',
    addressLine2: loggedInPersonDetails?.correspondenceAddress.addressLine2
      ? loggedInPersonDetails.correspondenceAddress.addressLine2
      : guestPersonalDetails?.address?.line2 ?? '',
    addressLine3: loggedInPersonDetails?.correspondenceAddress.addressLine3
      ? loggedInPersonDetails.correspondenceAddress.addressLine3
      : guestPersonalDetails?.address?.county ?? '',
    addressLine4: loggedInPersonDetails?.correspondenceAddress.addressLine4
      ? loggedInPersonDetails.correspondenceAddress.addressLine4
      : guestPersonalDetails?.address?.city ?? '',
    countryCode,
    postalCode: loggedInPersonDetails?.correspondenceAddress.postalCode
      ? loggedInPersonDetails.correspondenceAddress.postalCode
      : guestPersonalDetails?.address?.postcode ?? '',
  };
};

export const formatCustomerDetailsToPaymentApi = (
  personalDetails: PersonalDetails,
  address?: CustomerAddress
): PaymentFlexCustomerDetails => {
  return {
    email: personalDetails.email,
    mobileNumber: personalDetails.mobileNumber,
    name: `${personalDetails.firstName} ${personalDetails.lastName}`,
    billingAddress: address,
    shippingAddress: address,
  };
};

export const formatPaymentRequestPayload = (
  basket: CheckoutBasket | undefined,
  paymentType: PaymentType,
  customerDetails: PaymentFlexCustomerDetails
): PaymentFlexRequest => ({
  basketId: basket?.basketId ?? '',
  items: basket?.items.map((item: CheckoutBasketItem) => ({
    itemId: item.itemId,
    quoteId: item.quoteId,
  })),
  paymentDetails: {
    paymentType,
  },
  customerDetails,
});

export const formatPaymentRequest = (
  paymentFlexApiService: PaymentFlexApiService,
  domSanitizer: DomSanitizer
): OperatorFunction<
  [PaymentFlexRequest[], CoverPaymentOptions],
  PaymentFlexIframeUrls
> =>
  pipe(
    mergeMap(
      ([paymentFlexPayload, coverPaymentOptions]: [
        PaymentFlexRequest[],
        CoverPaymentOptions
      ]) =>
        forkJoin({
          [PaymentType.Card]: coverPaymentOptions.card?.fee
            ? paymentFlexApiService.request(paymentFlexPayload[0])
            : of(undefined),
          [PaymentType.DirectDebit]: coverPaymentOptions.directDebit?.fee
            ? paymentFlexApiService.request(paymentFlexPayload[1])
            : of(undefined),
        })
    ),
    map(
      (result: {
        [PaymentType.Card]?: PaymentFlexResponse;
        [PaymentType.DirectDebit]?: PaymentFlexResponse;
      }) => ({
        [PaymentType.Card]: result[PaymentType.Card]
          ? domSanitizer.bypassSecurityTrustResourceUrl(
              result[PaymentType.Card]!.paymentUrl
            )
          : undefined,
        [PaymentType.DirectDebit]: result[PaymentType.DirectDebit]
          ? domSanitizer.bypassSecurityTrustResourceUrl(
              result[PaymentType.DirectDebit]!.paymentUrl
            )
          : undefined,
      })
    )
  );

export const formatPaymentFlexToCheckoutRequest = (
  paymentFlexConfirmation?: PaymentFlexConfirmation,
  basket?: CheckoutBasket,
  address?: CustomerAddress,
  loggedInPersonDetails?: Person,
  guestPersonalDetails?: PersonalDetailsState,
  paymentMethod?: PaymentType
): CheckoutRequest | undefined => {
  if (
    !basket ||
    paymentFlexConfirmation?.Status !== PaymentFlexStatus.Success ||
    !address
  ) {
    return undefined;
  }
  return {
    basketId: basket.basketId,
    customer: {
      address,
      email: {
        main: loggedInPersonDetails?.contact.email
          ? loggedInPersonDetails.contact.email
          : guestPersonalDetails?.email ?? '',
      },
      firstName: loggedInPersonDetails?.personalDetails.firstName
        ? loggedInPersonDetails.personalDetails.firstName
        : guestPersonalDetails?.firstName ?? '',
      marketingPreferences: {
        ...(loggedInPersonDetails?.marketingPreferences ??
          guestPersonalDetails?.marketingPreferences),
      },
      preferredContactMethod: PlanDocumentsSendMethod.EMAIL,
      sendMethod: formatSendMethod(
        guestPersonalDetails?.paperlessPlanDocuments
      ),
      isOverrideAddress: true,
      surname: loggedInPersonDetails?.personalDetails.surname
        ? loggedInPersonDetails.personalDetails.surname
        : guestPersonalDetails?.lastName ?? '',
      title: loggedInPersonDetails?.personalDetails.title
        ? loggedInPersonDetails.personalDetails.title
        : guestPersonalDetails?.title ?? '',
      telephone: {
        mobile: loggedInPersonDetails?.contact.mobilePhoneNumber
          ? loggedInPersonDetails.contact.mobilePhoneNumber
          : guestPersonalDetails?.mobileNumber ?? '',
      },
    },
    items: basket.items.map((item) => ({
      itemId: item.itemId,
      itemType: ItemType.Quote,
      quoteId: item.quoteId,
    })),
    payment: {
      ...getPaymentData(paymentFlexConfirmation, paymentMethod),
    },
  };
};

export const getPaymentData = (
  paymentFlexConfirmation: PaymentFlexConfirmation,
  paymentType?: PaymentType
): PaymentByPaymentFlexToken => {
  if (!paymentType) {
    paymentType = PaymentType.DirectDebit;
  }
  if (paymentFlexConfirmation.CCType) {
    return {
      reference: paymentFlexConfirmation.UniqueReferenceNumber,
      paymentType,
      sourceType: 'P',
    };
  }

  return {
    directDebitDetails: {
      accountName: paymentFlexConfirmation.Holder,
      accountNumber: paymentFlexConfirmation.BankAccount,
      sortCode: paymentFlexConfirmation.BankSortCode.replace(/-/g, ''),
    },
    paymentType,
    preferredPaymentDay: paymentFlexConfirmation.PaymentDay,
  };
};

export const formatSendMethod = (
  paperlessPlanDocuments?: boolean
): PlanDocumentsSendMethod =>
  paperlessPlanDocuments === null || paperlessPlanDocuments === true
    ? PlanDocumentsSendMethod.EMAIL
    : PlanDocumentsSendMethod.BOTH;

export const formatPaymentConfirmation = (
  paymentConfirmationEvent: MessageEvent<string>
): PaymentFlexConfirmation | undefined => {
  try {
    const paymentConfirmation = JSON.parse(
      paymentConfirmationEvent.data
    ) as PaymentFlexConfirmation;
    return paymentConfirmation;
  } catch (error) {
    return undefined;
  }
};
