import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QuoteSummaryDependencies } from '@common/data-access-quote-summary';
import { CmsDataResolver } from '@common/data-access-shared';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';
import { CmsPage } from '@common/util-models';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { DgxComponentsLoadingModule } from '@domgen/dgx-fe-components/loading';
import { DgxFormsLayoutModule } from '@domgen/dgx-fe-forms/layout';
import { DgxFormsRadioModule } from '@domgen/dgx-fe-forms/radio';
import { CheckoutPaymentOptionsComponent } from './components/checkout-payment-options/checkout-payment-options.component';
import { PaymentMethodFormComponent } from './components/payment-method-form/payment-method-form.component';
import { PaymentFlexApiService } from './services/payment-flex-api/payment-flex-api.service';

@NgModule({
  imports: [
    CommonModule,
    CommonUiWhitelabelComponentsModule,
    DgxComponentsLoadingModule,
    DgxFormsLayoutModule,
    DgxFormsRadioModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: CheckoutPaymentOptionsComponent,
        resolve: {
          cmsData: CmsDataResolver,
        },
        data: {
          cmsDataPage: CmsPage.CheckoutPaymentOptions,
        },
      },
    ]),
    SharedUiComponentsCoreModule,
  ],
  providers: [PaymentFlexApiService, ...QuoteSummaryDependencies],
  declarations: [CheckoutPaymentOptionsComponent, PaymentMethodFormComponent],
})
export class CommonFeatureCheckoutPaymentOptionsModule {}
