<ng-container *ngIf="cs.vm$ | async as vm">
  <div class="container">
    <h2 class="my-6">Payment details</h2>

    <div class="grid-row">
      <div class="xs-12 md-6">
        <wl-your-details [detail]="vm.yourDetails"></wl-your-details>
      </div>
      <div class="sm--hidden md--block md-1"></div>
      <div class="xs-12 md-5">
        <div class="quote-section">
          <wl-quote-summary
            *ngIf="qscs.vm$ | async as qsvm"
            [config]="qsvm.quoteSummaryConfig"
            [multipleExcessAvailable]="qsvm.multipleExcessAvailable"
            [quote]="qsvm.quote"
            [selectedPaymentType]="qsvm.selectedPaymentType"
            class="xs--hidden md--block mb-6"
          ></wl-quote-summary>
        </div>
      </div>
    </div>

    <h3 class="pb-6">Payment options</h3>

    <checkout-payment-options-payment-method-form
      *ngIf="vm.paymentFlexUrls?.C"
      [buttonVisible]="!vm.iframeVisible"
      [paymentOptions]="vm.paymentOptions"
      (submitPaymentMethod)="cs.onSubmit()"
      (paymentMethodChange)="cs.updatePaymentMethod($event)"
    ></checkout-payment-options-payment-method-form>

    <div class="grid-row">
      <div
        class="xs-12 md-6"
        *sharedSectionLoading="!vm.iframeVisible && vm.paymentFlexUrls?.C"
      >
        <iframe
          *ngIf="vm.iframeVisible"
          title="Payment options"
          [src]="
            vm.paymentMethod === 'C'
              ? vm.paymentFlexUrls?.C
              : vm.paymentFlexUrls?.D
          "
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div class="sm--hidden md--block md-1"></div>
      <div class="xs-12 md-5"></div>
    </div>
  </div>

  <wl-quote-summary
    *ngIf="qscs.vm$ | async as qsvm"
    [config]="qsvm.quoteSummaryConfigSmall"
    [multipleExcessAvailable]="qsvm.multipleExcessAvailable"
    [quote]="qsvm.quote"
    [selectedPaymentType]="qsvm.selectedPaymentType"
    class="md--hidden xs--inline mb-6"
  ></wl-quote-summary>
</ng-container>
