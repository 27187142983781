<h3>Paperless documents</h3>

<p class="info" [innerHtml]="infoText"></p>

<dgx-dfb-form
  *ngIf="vm$ | async as vm"
  [fieldset]="vm.fieldDef"
  [form]="vm.formGroup"
  [validate]="vm.validate"
  (analytics)="handleAnalytics($event)"
>
</dgx-dfb-form>

<div class="description">
  Going paperless means there's less post to deal with, file or recycle.
</div>
