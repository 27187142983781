import { Component, OnDestroy } from '@angular/core';
import { NavigationError, NavigationStart, Router } from '@angular/router';
import { ErrorService } from '@common/util-foundation';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sales-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'sales';
  private subscriptions = new Subscription();

  popStateNavigation$ = this.router.events.pipe(
    filter(
      (allEvents) =>
        !!(allEvents instanceof NavigationStart) &&
        allEvents.navigationTrigger === 'popstate'
    )
  );

  navigationError$ = this.router.events.pipe(
    filter((allEvents) => !!(allEvents instanceof NavigationError))
  );

  constructor(private errorService: ErrorService, private router: Router) {
    this.subscriptions.add(
      this.navigationError$.subscribe(() => {
        this.errorService.handleError();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
