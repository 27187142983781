import {
  mapCheckoutPageCmsData,
  mapCmsFormData,
} from '@common/data-access-shared';
import { CmsDataUrl, CmsPage } from '@common/util-models';

export const cmsDataUrl: CmsDataUrl = {
  [CmsPage.Appliance]: {
    id: CmsPage.Appliance,
    url: `${CmsPage.Appliance}-applianceForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.Quote]: {
    id: CmsPage.Quote,
    url: `${CmsPage.Quote}.json`,
  },
  [CmsPage.FAQs]: {
    id: CmsPage.FAQs,
    url: CmsPage.FAQs,
  },
  [CmsPage.Checkout]: {
    id: CmsPage.Checkout,
    url: `${CmsPage.Checkout}.json`,
    mapper: mapCheckoutPageCmsData,
  },
  [CmsPage.CheckoutPersonalDetails]: {
    id: CmsPage.CheckoutPersonalDetails,
    url: `${CmsPage.Appliance}-checkoutPersonalDetailsForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutDirectDebit]: {
    id: CmsPage.CheckoutDirectDebit,
    url: `${CmsPage.Appliance}-checkoutDirectDebitForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutPaymentOptions]: {
    id: CmsPage.CheckoutPaymentOptions,
    url: `${CmsPage.CheckoutPaymentOptions}.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutLoggedInUser]: {
    id: CmsPage.CheckoutLoggedInUser,
    url: `${CmsPage.Appliance}-checkoutLoggedInUser.json`,
  },
  [CmsPage.CheckoutSelectPayment]: {
    id: CmsPage.CheckoutSelectPayment,
    url: `${CmsPage.Appliance}-checkoutSelectPayment.json`,
  },
};
