import { Quote, QuoteCms, QuoteSummaryConfig } from '@common/util-models';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
  defaultQuoteSummaryConfig,
  defaultQuoteSummaryConfigSmall,
} from '../constants/default-quote-summary-config.constant';
import {
  CheckoutTypes,
  MultiQuoteViewModel,
  SingleQuoteViewModel,
  TermsAndConditionsAssets,
} from '../services/quote-summary-component/quote-summary-component.service';

export class MockQuoteSummaryComponentService {
  public mqvm$ = new BehaviorSubject<MultiQuoteViewModel>({
    multiItemQuotes: [{} as Quote],
    multipleExcessAvailable: false,
    quoteRemoteStateUnstable: false,
    quoteSummaryConfig: defaultQuoteSummaryConfig,
    quoteSummaryConfigSmall: defaultQuoteSummaryConfigSmall,
    checkoutType: CheckoutTypes.product,
  });

  public vm$ = new BehaviorSubject<SingleQuoteViewModel>({
    multipleExcessAvailable: false,
    quote: {} as Quote,
    quoteRemoteStateUnstable: false,
    quoteSummaryConfig: defaultQuoteSummaryConfig,
    quoteSummaryConfigSmall: defaultQuoteSummaryConfigSmall,
  });

  checkoutType$ = new BehaviorSubject<CheckoutTypes>(CheckoutTypes.product);

  patchQuoteSummaryConfig(config: Partial<QuoteSummaryConfig>): void {
    const quoteSummaryConfig = {
      ...defaultQuoteSummaryConfig,
      ...config,
    };

    const quoteSummaryConfigSmall = {
      ...defaultQuoteSummaryConfig,
      ...config,
      isForSmallScreen: true,
    };

    this.mqvm$.next({
      ...this.mqvm,
      quoteSummaryConfig,
      quoteSummaryConfigSmall,
    });
    this.vm$.next({
      ...this.vm,
      quoteSummaryConfig,
      quoteSummaryConfigSmall,
    });
  }

  getTermsAndIPIDDocuments(
    quoteCmsData: QuoteCms
  ): Observable<TermsAndConditionsAssets> {
    return of({} as TermsAndConditionsAssets);
  }

  get mqvm() {
    return this.mqvm$.value;
  }
  get vm() {
    return this.vm$.value;
  }
}
