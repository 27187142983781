import { CountryIso2Code, CountryIso3Code } from '@domgen/dgx-fe-i18n';

export interface PaymentFlexConfig {
  countryIso2Code: CountryIso2Code;
  countryIso3Code: CountryIso3Code;
  config: {
    wlClient: string;
    catalogueStore: string;
    action: string;
    route?: string;
  };
  paymentApiUrl?: string;
}
