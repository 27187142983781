import { Component } from '@angular/core';
import { QuoteSummaryComponentService } from '@common/data-access-quote-summary';
import { CheckoutPaymentOptionsComponentService } from '../../services/checkout-payment-options-component/checkout-payment-options-component.service';

@Component({
  selector: 'checkout-payment-options',
  templateUrl: './checkout-payment-options.component.html',
  styleUrls: ['./checkout-payment-options.component.scss'],
  providers: [CheckoutPaymentOptionsComponentService],
})
export class CheckoutPaymentOptionsComponent {
  constructor(
    readonly cs: CheckoutPaymentOptionsComponentService,
    readonly qscs: QuoteSummaryComponentService
  ) {}
}
