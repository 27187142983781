import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ApplianceDetailsQueryParamsFormDataProvider,
  ApplianceDetailsStoreFeatureConfigFormDataProvider,
} from '@common/data-access-appliance-details';
import {
  QUERY_PARAMS_KEY,
  QueryParamsEffects,
  QueryParamsPartialState,
  RestartJourneyEffects,
  queryParamsReducer,
} from '@common/data-access-shared';
import {
  PAYMENT_FLEX_CONFIG,
  paymentFlexConfigFactory,
} from '@common/feature-checkout-payment-options';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
  META_REDUCERS,
} from '@common/feature-core';
import {
  ApplianceDetailsUiModule,
  CommonUiSalesComponentsModule,
} from '@common/ui-sales-components';
import {
  APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
  BuildConfigService,
  ENVIRONMENT,
  RUNTIME_CONFIG,
} from '@common/util-foundation';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { COUNTRY_ISO_2_CODE, COUNTRY_ISO_3_CODE } from '@domgen/dgx-fe-i18n';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@sales/util-environment';
import { SharedDataAccessNavigationModule } from '@shared/data-access-navigation';
import { SalesAppComponent } from './components/sales-app.component';
import { NAVIGATION_RULES } from './modules/navigation-rules.config';
import { SalesFeatureShellRoutingModule } from './sales-feature-shell-router.module';

const modules = [
  SalesFeatureShellRoutingModule,
  CommonUiSalesComponentsModule,
  HttpClientModule,
  SharedUiComponentsCoreModule,
];

@NgModule({
  declarations: [SalesAppComponent],
  imports: [
    ...modules,
    CommonModule,
    StoreModule.forRoot(
      {
        [QUERY_PARAMS_KEY]: queryParamsReducer,
      } as ActionReducerMap<QueryParamsPartialState & Record<string, unknown>>,
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    SharedDataAccessNavigationModule.forRoot({
      defaultRedirectUrl: environment.applianceDetailsPage,
      navigationRules: [...NAVIGATION_RULES],
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    EffectsModule.forRoot([RestartJourneyEffects, QueryParamsEffects]),
    ApplianceDetailsUiModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },

    {
      provide: APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
      multi: true,
      useClass: ApplianceDetailsQueryParamsFormDataProvider,
    },
    {
      provide: APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
      multi: true,
      useClass: ApplianceDetailsStoreFeatureConfigFormDataProvider,
    },

    {
      provide: PAYMENT_FLEX_CONFIG,
      useFactory: paymentFlexConfigFactory,
      deps: [
        RUNTIME_CONFIG,
        BuildConfigService,
        COUNTRY_ISO_2_CODE,
        COUNTRY_ISO_3_CODE,
      ],
    },
  ],
  exports: [...modules, SalesAppComponent],
})
export class SalesFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<SalesFeatureShellModule> {
    return {
      ngModule: SalesFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}
