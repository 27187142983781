import { NgModule } from '@angular/core';
import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import { SalesFeatureShellModule } from '@sales/feature-shell';
import { environment } from '@sales/util-environment';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { iconConfig } from '../assets/asset-icons-config';
import { AppComponent } from './app.component';
import { COMPONENT_MAPPING } from './cms-config/cms-component-mapping.config';
import { cmsDataUrl } from './cms-config/cms-data-url';
import { gaTagsConfig } from './ga-tags-config/ga-tags-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SalesFeatureShellModule.forRoot({
      componentMapping: COMPONENT_MAPPING,
      gaTagsConfig,
      cmsDataUrl,
    }),
    ScullyLibModule,
    DgxComponentsIconModule.forRoot(iconConfig),
  ],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
