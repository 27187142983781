import { QuoteSummaryComponentService } from './lib/services/quote-summary-component/quote-summary-component.service';
import { QuoteSummaryGaMapperService } from './lib/services/quote-summary-ga-mapper/quote-summary-ga-mapper.service';
import { QuoteSummaryGaTaggingService } from './lib/services/quote-summary-ga-tagging/quote-summary-ga-tagging.service';
import { QuoteSummaryRedeyeMapperService } from './lib/services/quote-summary-redeye-tagging/quote-summary-redeye-mapper.service';
import { QuoteSummaryRedeyeTaggingService } from './lib/services/quote-summary-redeye-tagging/quote-summary-redeye-tagging.service';

export * from './lib/constants';
export * from './lib/mocks';
export * from './lib/services/quote-summary-component/quote-summary-component.service';
export * from './lib/services/quote-summary-ga-mapper/quote-summary-ga-mapper.service';
export * from './lib/services/quote-summary-ga-tagging/quote-summary-ga-tagging.service';
export * from './lib/services/quote-summary-redeye-tagging/quote-summary-redeye-mapper.service';
export * from './lib/services/quote-summary-redeye-tagging/quote-summary-redeye-tagging.service';

export const QuoteSummaryDependencies = [
  QuoteSummaryComponentService,
  QuoteSummaryGaTaggingService,
  QuoteSummaryGaMapperService,
  QuoteSummaryRedeyeTaggingService,
  QuoteSummaryRedeyeMapperService,
];
