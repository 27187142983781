import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PaymentFormField } from './payment-selection-form-field.enum';

export class PaymentSelectionForm extends UntypedFormGroup {
  constructor() {
    super({
      [PaymentFormField.PaymentMethod]: new UntypedFormControl(null, [
        Validators.required,
      ]),
    });
  }
}
