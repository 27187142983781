import { Injectable } from '@angular/core';
import { applianceIsHeating } from '@common/util-foundation';
import { GoogleTagConfig, Quote } from '@common/util-models';
import { GaHelperService } from '@common/util-foundation';

@Injectable({ providedIn: 'any' })
export class QuoteSummaryGaMapperService {
  constructor(private gaHelper: GaHelperService) {}
  getDeleteQuoteFromBasketData(gaTags: GoogleTagConfig, quote: Quote) {
    return {
      ...gaTags.deleteItemFromBasket,
      eventLabel: `${quote.brandName} ${quote.applianceName} | ${
        quote.cover.paymentOptions.directDebit?.subsequentPayment ??
        quote.cover.paymentOptions.card?.fee
      }`,
    };
  }

  getCancelQuoteDeletionFromBasketData(gaTags: GoogleTagConfig, quote: Quote) {
    return {
      ...gaTags.cancelItemDeletionFromBasket,
      eventLabel: `${quote.brandName} ${quote.applianceName} | ${quote.cover.paymentOptions.directDebit?.subsequentPayment}`,
    };
  }

  getConfirmQuoteDeletionFromBasketData(
    gaTags: GoogleTagConfig,
    quote: Quote,
    pageName: string
  ) {
    const { cover } = quote;
    const isHeating = applianceIsHeating(quote);
    return {
      ...gaTags.confirmItemDeletionFromBasket,
      ecommerce: {
        remove: {
          actionField: {
            list: this.getPageMap().get(pageName),
          },
          products: [
            {
              id: cover.schemeCode,
              name: `${quote.brandName} ${quote.applianceName}`,
              category: quote.applianceCategory,
              price:
                cover.paymentOptions.directDebit?.subsequentPayment ??
                cover.paymentOptions.card?.fee,
              quantity: 1,
              ...this.gaHelper.getDimensions({
                fee:
                  cover.paymentOptions.directDebit?.subsequentPayment ??
                  cover.paymentOptions.card?.fee,
                companyCode: cover.companyCode,
                applianceName: quote.applianceCategory,
                coverType: isHeating ? cover.coverType : '',
                brandName: quote.brandName,
              }),
            },
          ],
        },
      },
    };
  }

  getPageMap(): Map<string, string> {
    return new Map([
      ['/products', 'Appliance Details'],
      ['/quote', 'Quote Summary'],
      ['/checkout', 'Checkout Option'],
    ]);
  }
}
