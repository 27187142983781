import { Inject, Injectable, Optional } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PaymentFlexConfig } from '../models/payment-flex/payment-flex-config.interface';
import { PAYMENT_FLEX_CONFIG } from '../tokens/payment-flex-config.token';

@Injectable({
  providedIn: 'root',
})
export class HasNoPaymentOptionsGuard implements CanActivate {
  constructor(
    @Optional()
    @Inject(PAYMENT_FLEX_CONFIG)
    private readonly paymentConfig: PaymentFlexConfig,
    private readonly router: Router
  ) {}

  canActivate(): Promise<boolean> | boolean {
    if (
      this.paymentConfig &&
      this.paymentConfig.paymentApiUrl &&
      this.paymentConfig.config.route
    ) {
      return this.router.navigateByUrl(this.paymentConfig.config.route);
    }

    return true;
  }
}
