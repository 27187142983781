/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CoverPaymentOptions, PaymentType } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { FormField } from '@domgen/dgx-fe-forms/layout';
import { FormOption } from '@domgen/dgx-fe-forms/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaymentFormField } from '../../models/payment-selection/payment-selection-form-field.enum';
import { PaymentSelectionForm } from '../../models/payment-selection/payment-selection-form.model';

@UntilDestroy()
@Component({
  selector: 'checkout-payment-options-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodFormComponent implements OnInit {
  @Input() set paymentOptions(paymentOptions: CoverPaymentOptions) {
    this.setOptions(paymentOptions);
  }
  @Input() buttonVisible?: boolean;
  @Output() submitPaymentMethod: EventEmitter<void> = new EventEmitter();
  @Output() paymentMethodChange: EventEmitter<PaymentType> = new EventEmitter();

  form!: PaymentSelectionForm;
  readonly formField: FormField = {
    fieldLabel: '',
    fieldRequired: true,
  };
  options: FormOption[] = [];

  ngOnInit(): void {
    this.form = new PaymentSelectionForm();
    this.detectFormFieldChanges();
  }

  detectFormFieldChanges(): void {
    this.form
      .get(PaymentFormField.PaymentMethod)!
      .valueChanges.pipe(untilDestroyed(this), filterNullUndefined())
      .subscribe((paymentType: PaymentType) =>
        this.paymentMethodChange.emit(paymentType)
      );
  }

  setOptions(paymentOptions?: CoverPaymentOptions): void {
    if (!paymentOptions) {
      this.options = [];
      return;
    }
    this.options = [
      {
        value: PaymentType.DirectDebit,
        label: `${
          paymentOptions.directDebit!.numPayments
        } monthly payments of £${
          paymentOptions.directDebit!.subsequentPayment
        } a month`,
      },
      {
        value: PaymentType.Card,
        label: `One off payment of £${paymentOptions.card!.fee}`,
      },
    ];
  }

  onSubmit(): void {
    this.submitPaymentMethod.emit();
  }
}
