import { Injectable } from '@angular/core';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import { QuotesFacade } from '@common/data-access-quotes';
import { SelectPaymentFacade } from '@common/data-access-select-payment';
import {
  DiscountStoreConfig,
  PaymentType,
  ProductType,
  Quote,
  QuoteCms,
  QuoteSummaryConfig,
} from '@common/util-models';
import { CmsAsset } from '@domgen/dgx-fe-business-models';
import { combineLatestObj } from '@domgen/dgx-fe-common';
import { AlertDialogService } from '@domgen/dgx-fe-components-core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, queueScheduler } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';

import { defaultQuoteSummaryConfig } from '../../constants';
import { QuoteSummaryGaTaggingService } from '../quote-summary-ga-tagging/quote-summary-ga-tagging.service';
import { QuoteSummaryRedeyeTaggingService } from '../quote-summary-redeye-tagging/quote-summary-redeye-tagging.service';

export interface ComponentState {
  quoteSummaryConfig: QuoteSummaryConfig;
}

export interface ViewModel {
  multipleExcessAvailable: boolean;
  quoteRemoteStateUnstable: boolean;
  quoteSummaryConfig: QuoteSummaryConfig;
  quoteSummaryConfigSmall: QuoteSummaryConfig;
  selectedPaymentType?: PaymentType;
}

export interface SingleQuoteViewModel extends ViewModel {
  quote: Quote;
}

export interface MultiQuoteViewModel extends ViewModel {
  multiItemQuotes: Quote[];
  checkoutType: CheckoutTypes;
}

export enum CheckoutTypes {
  product = 'product',
  plumbingAndDrainage = 'plumbingAndDrainage',
  upsell = 'upsell',
}

export interface TermsAndConditionsAssets {
  termsAndConditionsAsset: CmsAsset;
  ipidAsset: CmsAsset;
}

@Injectable()
export class QuoteSummaryComponentService extends ComponentStore<ComponentState> {
  // Selectors
  private readonly quoteSummaryConfig$: Observable<QuoteSummaryConfig> =
    this.select((state: ComponentState) => ({
      ...state.quoteSummaryConfig,
      isForSmallScreen: false,
    }));

  private readonly quoteSummaryConfigSmall$: Observable<QuoteSummaryConfig> =
    this.quoteSummaryConfig$.pipe(
      map((quoteSummaryConfig: QuoteSummaryConfig) => ({
        ...quoteSummaryConfig,
        isForSmallScreen: true,
      }))
    );

  // Updaters
  readonly patchQuoteSummaryConfig = this.updater(
    (
      state: ComponentState,
      quoteSummaryConfig: Partial<QuoteSummaryConfig>
    ) => ({
      ...state,
      quoteSummaryConfig: {
        ...state.quoteSummaryConfig,
        ...quoteSummaryConfig,
      },
    })
  );

  readonly updateQuoteSummaryWithDiscountPercentage = this.updater(
    (
      state: ComponentState,
      discountStore: DiscountStoreConfig | undefined
    ) => ({
      ...state,
      quoteSummaryConfig: {
        ...state.quoteSummaryConfig,
        discountPercentage: discountStore?.discount,
      },
    })
  )(this.featureConfigFacade.discountStore$.pipe(observeOn(queueScheduler)));

  readonly vm$: Observable<SingleQuoteViewModel> = combineLatestObj({
    multipleExcessAvailable: this.quotesFacade.isMultipleExcessAvailable$,
    quoteRemoteStateUnstable: this.quotesFacade.quoteRemoteStateUnstable$,
    quote: this.quotesFacade.quote$,
    quoteSummaryConfig: this.quoteSummaryConfig$,
    quoteSummaryConfigSmall: this.quoteSummaryConfigSmall$,
    selectedPaymentType: this.selectPaymentFacade.selectedPayment$,
  });

  readonly mqvm$: Observable<MultiQuoteViewModel> = combineLatestObj({
    multiItemQuotes: this.quotesFacade.multiItemQuotes$,
    multipleExcessAvailable: this.quotesFacade.isMultipleExcessAvailable$,
    quoteRemoteStateUnstable: this.quotesFacade.quoteRemoteStateUnstable$,
    quoteSummaryConfig: this.quoteSummaryConfig$,
    quoteSummaryConfigSmall: this.quoteSummaryConfigSmall$,
    selectedPaymentType: this.selectPaymentFacade.selectedPayment$,
    checkoutType: this.quotesFacade.checkoutType$,
  });

  constructor(
    private readonly alertDialogService: AlertDialogService,
    private readonly quotesFacade: QuotesFacade,
    private readonly quoteSummaryGaTaggingService: QuoteSummaryGaTaggingService,
    private readonly featureConfigFacade: FeatureConfigFacade,
    private readonly quoteSummaryRedeyeTaggingService: QuoteSummaryRedeyeTaggingService,
    private readonly selectPaymentFacade: SelectPaymentFacade
  ) {
    super({
      quoteSummaryConfig: defaultQuoteSummaryConfig,
    });
  }

  confirmRemovalQuoteFromMultiQuote(quote: Quote & { last: boolean }): void {
    this.quoteSummaryGaTaggingService.handleQuoteRemoval(quote);
    this.alertDialogService.openError({
      ariaLabel: 'remove appliance from your quote',
      data: {
        title: `Remove ${quote.brandName} ${quote.applianceName} from your quote?`,
        body: quote.last
          ? 'Removing this item will clear the quote so you can start again.'
          : '',
        primaryCta: {
          text: 'Remove',
          hiddenIcon: true,
          click: () => this.removeSelectedQuoteFromMultiQuote(quote),
        },
        secondaryCta: {
          text: 'Cancel',
          hiddenIcon: true,
          click: () =>
            this.quoteSummaryGaTaggingService.handleCancelQuoteRemoval(quote),
        },
      },
    });
  }

  /**
   * todo: remove the same logic from CheckoutPersonalDetailsComponent
   * @param quoteCmsData
   * @returns
   */
  getTermsAndIPIDDocuments(
    quoteCmsData: QuoteCms
  ): Observable<TermsAndConditionsAssets> {
    return this.quotesFacade.checkoutType$.pipe(
      map((checkoutType) => {
        const hasPDCover = checkoutType === CheckoutTypes.plumbingAndDrainage;
        return this.getTermsAndConditions(quoteCmsData, hasPDCover);
      })
    );
  }

  private removeSelectedQuoteFromMultiQuote(quote: Quote): void {
    this.quoteSummaryRedeyeTaggingService.tagDeleteQuote(quote);
    this.quoteSummaryGaTaggingService.handleConfirmQuoteRemoval(quote);
    const itemId = quote.itemId;
    const quoteId = quote.cover.quoteId;
    const isPlumbingAndDrainage = quote.cover.productType === ProductType.PD;
    this.quotesFacade.removeSelectedItem({
      itemId,
      quoteId,
      isPlumbingAndDrainage,
    });
  }

  private getTermsAndConditions(
    quoteCmsData: QuoteCms,
    hasPDCover: boolean
  ): TermsAndConditionsAssets {
    return {
      termsAndConditionsAsset: this.getTermsAndConditionsPDF(
        quoteCmsData,
        hasPDCover
      ),
      ipidAsset: this.getIpidPDF(quoteCmsData, hasPDCover),
    };
  }

  private getTermsAndConditionsPDF(
    quoteCmsData: QuoteCms,
    hasPDCover: boolean
  ): CmsAsset {
    return (
      (hasPDCover && quoteCmsData.termsAndConditionsPDAsset) ||
      quoteCmsData.termsAndConditionsAsset
    );
  }

  private getIpidPDF(quoteCmsData: QuoteCms, hasPDCover: boolean): CmsAsset {
    return (hasPDCover && quoteCmsData.ipidPDAsset) || quoteCmsData.ipidAsset;
  }
}
