import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GaTaggingService, GA_TAGS, pushToGa } from '@common/util-foundation';
import { GoogleTagConfig, Quote } from '@common/util-models';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuoteSummaryGaMapperService } from '../quote-summary-ga-mapper/quote-summary-ga-mapper.service';

@Injectable()
export class QuoteSummaryGaTaggingService extends ComponentStore<never> {
  private analyticsQuoteDeleteClick$ = new Subject<Quote>();
  private analyticsConfirmQuoteRemoval$ = new Subject<Quote>();
  private analyticsCancelQuoteRemoval$ = new Subject<Quote>();

  constructor(
    @Inject(GA_TAGS) private gaTags: GoogleTagConfig,
    private gaTaggingService: GaTaggingService,
    private quoteSummaryGaMapperService: QuoteSummaryGaMapperService,
    private router: Router
  ) {
    super();
  }

  private deleteQuoteFromBasket = this.effect((trigger$: Observable<Quote>) =>
    trigger$.pipe(
      map((quote) =>
        this.quoteSummaryGaMapperService.getDeleteQuoteFromBasketData(
          this.gaTags,
          quote
        )
      ),
      pushToGa(this.gaTaggingService)
    )
  )(this.analyticsQuoteDeleteClick$);

  private cancelQuoteDeletionFromBasket = this.effect(
    (trigger$: Observable<Quote>) =>
      trigger$.pipe(
        map((quote) =>
          this.quoteSummaryGaMapperService.getCancelQuoteDeletionFromBasketData(
            this.gaTags,
            quote
          )
        ),
        pushToGa(this.gaTaggingService)
      )
  )(this.analyticsCancelQuoteRemoval$);

  private confirmQuoteDeletionFromBasket = this.effect(
    (trigger$: Observable<Quote>) =>
      trigger$.pipe(
        map((quote) =>
          this.quoteSummaryGaMapperService.getConfirmQuoteDeletionFromBasketData(
            this.gaTags,
            quote,
            this.router.url
          )
        ),
        pushToGa(this.gaTaggingService)
      )
  )(this.analyticsConfirmQuoteRemoval$);

  handleQuoteRemoval(quote: Quote) {
    this.analyticsQuoteDeleteClick$.next(quote);
  }

  handleConfirmQuoteRemoval(quote: Quote) {
    this.analyticsConfirmQuoteRemoval$.next(quote);
  }

  handleCancelQuoteRemoval(quote: Quote) {
    this.analyticsCancelQuoteRemoval$.next(quote);
  }
}
