interface SvgIcon {
  name: string;
  url: string;
}
export const iconConfig: SvgIcon[] = [
  {
    name: 'protect',
    url: '/assets/images/protect.svg',
  },
  {
    name: 'expert',
    url: '/assets/images/expert.svg',
  },
  {
    name: 'extrasupport',
    url: '/assets/images/extrasupport.svg',
  },
  {
    name: 'callcentre',
    url: '/assets/images/callcentre.svg',
  },
  {
    name: 'ico-check',
    url: '/assets/images/ico-check.svg',
  },
];
