import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardPaymentSetupResolver } from '@common/data-access-card-payment';
import { HasSelectedBasketItemsGuard } from '@common/data-access-quotes';
import { HasNoPaymentOptionsGuard } from '@common/feature-checkout-payment-options';
import { ErrorComponent } from '@common/ui-sales-components';
import { StepCompletionNavigationGuard } from '@shared/data-access-navigation';
import { QuoteCmsPageResolver } from './resolvers/quote-cms-page.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: '.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    // TODO: add a guard
    path: 'quote',
    loadChildren: () =>
      import('@common/feature-quote').then(
        (module) => module.CommonFeatureQuoteModule
      ),
    canActivate: [StepCompletionNavigationGuard, HasSelectedBasketItemsGuard],
    resolve: {
      cmsData: QuoteCmsPageResolver,
    },
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'quote.html',
    redirectTo: '/quote',
    pathMatch: 'full',
  },
  {
    path: 'plumbing-and-drainage-details',
    loadChildren: () =>
      import('@common/feature-plumbing-and-drainage').then(
        (module) => module.CommonFeaturePlumbingAndDrainageDetailsModule
      ),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('@common/feature-appliance-details').then(
        (module) => module.CommonFeatureApplianceDetailsModule
      ),
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'products.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    path: 'checkout',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@common/feature-checkout-landing').then(
            (m) => m.CommonFeatureCheckoutLandingModule
          ),
      },
      {
        path: 'personal-details',
        loadChildren: () =>
          import('@common/feature-checkout-personal-details').then(
            (m) => m.CommonFeatureCheckoutPersonalDetailsModule
          ),
        resolve: {
          quoteData: QuoteCmsPageResolver,
        },
      },
      {
        path: 'logged-in',
        loadChildren: () =>
          import('@common/feature-checkout-logged-in-user').then(
            (m) => m.CommonFeatureCheckoutLoggedInUserModule
          ),
        resolve: {
          quoteData: QuoteCmsPageResolver,
        },
      },
      {
        path: 'direct-debit-payment',
        loadChildren: () =>
          import('@common/feature-checkout-direct-debit').then(
            (m) => m.CommonFeatureCheckoutDirectDebitModule
          ),
        canActivate: [HasNoPaymentOptionsGuard],
      },
      {
        path: 'order-confirmation',
        loadChildren: () =>
          import('@common/feature-order-confirmation').then(
            (m) => m.CommonFeatureOrderConfirmationModule
          ),
        resolve: {
          cmsData: QuoteCmsPageResolver,
        },
      },
      {
        path: 'card-payment',
        loadChildren: () =>
          import('@common/feature-checkout-card-payment').then(
            (m) => m.CommonFeatureCheckoutCardPaymentModule
          ),
        resolve: {
          setupCardPayment: CardPaymentSetupResolver,
        },
      },
      {
        path: 'select-payment',
        loadChildren: () =>
          import('@common/feature-checkout-select-payment').then(
            (m) => m.CommonFeatureCheckoutSelectPaymentModule
          ),
        resolve: {
          quoteData: QuoteCmsPageResolver,
        },
        canActivate: [HasNoPaymentOptionsGuard],
      },
      {
        path: 'payment-options',
        loadChildren: () =>
          import('@common/feature-checkout-payment-options').then(
            (m) => m.CommonFeatureCheckoutPaymentOptionsModule
          ),
      },
    ],
    canActivate: [StepCompletionNavigationGuard, HasSelectedBasketItemsGuard],
  },
  { path: 'error', data: { title: 'Error' }, component: ErrorComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class SalesFeatureShellRoutingModule {} // Main routes for application
